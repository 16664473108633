exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplikacje-webowe-js": () => import("./../../../src/pages/aplikacje-webowe.js" /* webpackChunkName: "component---src-pages-aplikacje-webowe-js" */),
  "component---src-pages-branding-js": () => import("./../../../src/pages/branding.js" /* webpackChunkName: "component---src-pages-branding-js" */),
  "component---src-pages-estymacja-js": () => import("./../../../src/pages/estymacja.js" /* webpackChunkName: "component---src-pages-estymacja-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-ceramizator-js": () => import("./../../../src/pages/portfolio/ceramizator.js" /* webpackChunkName: "component---src-pages-portfolio-ceramizator-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-konstancin-clinic-js": () => import("./../../../src/pages/portfolio/konstancin-clinic.js" /* webpackChunkName: "component---src-pages-portfolio-konstancin-clinic-js" */),
  "component---src-pages-portfolio-speak-in-color-js": () => import("./../../../src/pages/portfolio/speak-in-color.js" /* webpackChunkName: "component---src-pages-portfolio-speak-in-color-js" */),
  "component---src-pages-portfolio-varis-js": () => import("./../../../src/pages/portfolio/varis.js" /* webpackChunkName: "component---src-pages-portfolio-varis-js" */),
  "component---src-pages-sklep-internetowy-js": () => import("./../../../src/pages/sklep-internetowy.js" /* webpackChunkName: "component---src-pages-sklep-internetowy-js" */),
  "component---src-pages-strona-internetowa-js": () => import("./../../../src/pages/strona-internetowa.js" /* webpackChunkName: "component---src-pages-strona-internetowa-js" */)
}

